import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import "./non-member-single.scss"

import {getCategories} from "../redux/api/apiAction"
import {selectCategory} from "../redux/registration/registrationAction"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card/card"

const NonMemberPage = () => {
    const dispatch = useDispatch()
    const {information} = useSelector(({registrationReducer}) => registrationReducer)
    const {user}  = useSelector(({apiReducer}) => apiReducer)
    const [tabIndex, setTabIndex] = useState(0)

    if(user === null) {
        useEffect(() => {
            dispatch(getCategories())
                .then((categories) => {
                if(information.unionMember){
                     dispatch(selectCategory(categories, information.unionMember.value))
                }else{
                    dispatch(onChangeByName("unionMember", "Delegate"))
                    dispatch(selectCategory(categories, "Delegate"))
                }
                    
                })
        }, [])
    }

    const onSelect = index => {
        setTabIndex(index)
    }

    const prevTab = () => setTabIndex(prev => prev - 1);
    const nextTab = () => setTabIndex(prev => prev + 1);

    return (
        <Layout>
            <p className={"txt-center mt-5"}>Welcome to the group registrations for the 14th PHC Congress.<br />Opening on December 18<br />Coming soon</p>
        </Layout>
    )
}

export default NonMemberPage
